<template>
  <div id="refund">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>财务管理</el-breadcrumb-item>
      <el-breadcrumb-item>退款管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">车牌号:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.plateNum"
              @keyup.enter.native="getList(1)"
            >
            </el-input>
          </li>
          <li>
            <span class="label">选择停车场:</span>
            <el-select
              v-model="filtrate.parkingLotId"
              filterable
              clearable
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option
                v-for="item in parkingLotList"
                :key="item.parkingLotId"
                :label="item.parkingLotName"
                :value="item.parkingLotId"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">审批状态:</span>
            <el-select
              v-model="filtrate.refundStatus"
              clearable
              placeholder="请选择"
              @change="getList(1)"
            >
              <el-option
                v-for="item in refundStatusList"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </li>
          <li>
            <span class="label">开始时间:</span>
            <el-date-picker
              v-model="filtrate.startTime"
              type="datetime"
              placeholder="选择开始时间"
              @change="getList(1)"
            >
            </el-date-picker>
          </li>
          <li>
            <span class="label">结束时间:</span>
            <el-date-picker
              v-model="filtrate.endTime"
              type="datetime"
              placeholder="选择结束时间"
              @change="getList(1)"
            >
            </el-date-picker>
          </li>
          <li>
            <el-button type="primary" size="medium" @click="getList(1)"
              >查询
            </el-button>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置
            </el-button>
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              type="primary"
              @click="exportExcel"
              v-if="buttonList.includes('refundList-export')"
              >导出报表
            </el-button>
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column prop="orderId" label="订单编号" align="center">
        </el-table-column>
        <el-table-column prop="refundStatus" label="退款状态" align="center">
        </el-table-column>
        <el-table-column prop="username" label="用户名" align="center">
        </el-table-column>
        <el-table-column prop="plateNum" label="车牌号" align="center">
        </el-table-column>
        <el-table-column
          prop="parkingLotName"
          label="停车场名称"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="parkingTime" label="停车时长" align="center">
        </el-table-column>
        <el-table-column
          prop="serviceStartTime"
          label="入场时间"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="serviceEndTime" label="出场时间" align="center">
        </el-table-column>
        <el-table-column prop="totalPrice" label="订单总金额" align="center">
        </el-table-column>
        <el-table-column prop="actualPayment" label="退款金额" align="center">
        </el-table-column>
        <el-table-column prop="payTime" label="支付时间" align="center">
        </el-table-column>
        <el-table-column prop="applyRemark" label="申请人备注" align="center">
        </el-table-column>
        <el-table-column prop="appliedAt" label="申请时间" align="center">
        </el-table-column>
        <el-table-column prop="checkedAt" label="处理时间" align="center">
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          v-if="buttonList.includes('refundList-approval')"
        >
          <template #default="scope">
            <span style="color: red" v-if="scope.row.refundSuccess === '已拒绝'"
              >已拒绝</span
            >
            <el-button
              v-else
              type="text"
              @click="approval(scope.row.refundId)"
              :disabled="scope.row.refundSuccess !== '申请退款中'"
              >{{
                scope.row.refundSuccess == "申请退款中"
                  ? "审批"
                  : scope.row.refundSuccess
              }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
    <approvalDialog ref="approvalDialog" @refresh="getList" />
    <exportDialog ref="exportDialog" />
  </div>
</template>
<script>
export default {
  components: {
    exportDialog: () => import("./exportDialog.vue"),
    approvalDialog: () => import("./approvalDialog.vue"),
  },
  data() {
    return {
      filtrate: {
        plateNum: "",
        parkingLotId: null,
        refundStatus: null,
        startTime: this.$moment().subtract(14, "days"),
        endTime: new Date(),
      },
      refundStatusList: [
        {
          label: "申请退款中",
          value: 0,
        },
        {
          label: "已同意",
          value: 1,
        },
        {
          label: "已拒绝",
          value: 2,
        },
      ],
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  computed: {
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
  },
  created() {
    this.getList();
  },
  methods: {
    exportExcel() {
      this.$refs.exportDialog.form = {
        time: [
          this.filtrate.startTime ? this.filtrate.startTime : "",
          this.filtrate.endTime ? this.filtrate.endTime : "",
        ],
        plateNum: this.filtrate.plateNum,
        parkingLotId: this.filtrate.parkingLotId,
        refundStatus: this.filtrate.refundStatus,
      };
      this.$refs.exportDialog.handleShow();
    },
    reset() {
      Object.assign(this.filtrate, {
        plateNum: "",
        parkingLotId: null,
        refundStatus: null,
        startTime: "",
        endTime: "",
      });
      this.getList(1);
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/order/refund/list", {
          pageNum: page,
          pageSize: this.table.pageSize,
          ...this.filtrate,
          parkingLotId: this.filtrate.parkingLotId
            ? this.filtrate.parkingLotId
            : null,
          refundStatus:
            this.filtrate.refundStatus === ""
              ? null
              : this.filtrate.refundStatus,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.appliedAt = this.$moment(item.appliedAt).format(
              "yyyy-MM-DD HH:mm:ss"
            );
            item.checkedAt = item.checkedAt
              ? this.$moment(item.checkedAt).format("yyyy-MM-DD HH:mm:ss")
              : "--";
            item.payTime = this.$moment(item.payTime).format(
              "yyyy-MM-DD HH:mm:ss"
            );
            item.serviceEndTime = this.$moment(item.serviceEndTime).format(
              "yyyy-MM-DD HH:mm:ss"
            );
            item.serviceStartTime = this.$moment(item.serviceStartTime).format(
              "yyyy-MM-DD HH:mm:ss"
            );
            item.actualPayment = item.requestAmount + item.requestWalletAmount;
          }
          this.table.tableData = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
    async approval(refundId) {
      this.$refs.approvalDialog.refundId = refundId;
      this.$refs.approvalDialog.changeShow();
    },
  },
};
</script>
<style lang="scss">
#refund {
  .el-table {
    .el-table__body-wrapper {
      .el-table__body {
        tbody {
          .el-table__row {
            .cell {
              .el-image {
                width: 53px;
                height: 38px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
